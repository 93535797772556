import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import SearchCompany from '../love/SearchCompany'

export default function BuyDialog3(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [system, setSystem] = React.useState(null);

    const [sid1, setSID1] = React.useState(null);
    const [sid2, setSID2] = React.useState(null);
    const [sid3, setSID3] = React.useState(null);
    const [sid4, setSID4] = React.useState(null);
    const [sid5, setSID5] = React.useState(null);
    const [sid6, setSID6] = React.useState(null);
    const [sid7, setSID7] = React.useState(null);
    const [sid8, setSID8] = React.useState(null);
    const [sid9, setSID9] = React.useState(null);
    const [sid10, setSID10] = React.useState(null);
    const [sid11, setSID11] = React.useState(null);
    const [sid12, setSID12] = React.useState(null);
    const [sid13, setSID13] = React.useState(null);
    const [sid14, setSID14] = React.useState(null);
    const [sid15, setSID15] = React.useState(null);
    const [sid16, setSID16] = React.useState(null);
    const [sid17, setSID17] = React.useState(null);
    const [sid18, setSID18] = React.useState(null);
    const [sid19, setSID19] = React.useState(null);
    const [sid20, setSID20] = React.useState(null);

    const [sid1v, setSID1v] = React.useState(null);
    const [sid2v, setSID2v] = React.useState(null);
    const [sid3v, setSID3v] = React.useState(null);
    const [sid4v, setSID4v] = React.useState(null);
    const [sid5v, setSID5v] = React.useState(null);
    const [sid6v, setSID6v] = React.useState(null);
    const [sid7v, setSID7v] = React.useState(null);
    const [sid8v, setSID8v] = React.useState(null);
    const [sid9v, setSID9v] = React.useState(null);
    const [sid10v, setSID10v] = React.useState(null);
    const [sid11v, setSID11v] = React.useState(null);
    const [sid12v, setSID12v] = React.useState(null);
    const [sid13v, setSID13v] = React.useState(null);
    const [sid14v, setSID14v] = React.useState(null);
    const [sid15v, setSID15v] = React.useState(null);
    const [sid16v, setSID16v] = React.useState(null);
    const [sid17v, setSID17v] = React.useState(null);
    const [sid18v, setSID18v] = React.useState(null);
    const [sid19v, setSID19v] = React.useState(null);
    const [sid20v, setSID20v] = React.useState(null);


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    // sn : String, id : String, ymd : String, dir : Int = 0, setting : TurtleLoveSetting
    const handleOKClose = () => {
        let param = {
            sn: getId(),
            id: props.row.id,
            name: system
        }

        let allString = ""
        let hasDouble = false

        if (sid1 != null) {
            param['sid1'] = sid1
            allString = allString + "," + sid1
        }
        if (sid2 != null) {
            param['sid2'] = sid2
            if (!hasDouble) hasDouble = allString.includes(sid2)
            allString = allString + "," + sid2
        }
        if (sid3 != null) {
            param['sid3'] = sid3
            if (!hasDouble) hasDouble = allString.includes(sid3)
            allString = allString + "," + sid3
        }

        if (sid4 != null) {
            param['sid4'] = sid4
            if (!hasDouble) hasDouble = allString.includes(sid4)
            allString = allString + "," + sid4
        }

        if (sid5 != null) {
            param['sid5'] = sid5
            if (!hasDouble) hasDouble = allString.includes(sid5)
            allString = allString + "," + sid5
        }

        if (sid6 != null) {
            param['sid6'] = sid6
            if (!hasDouble) hasDouble = allString.includes(sid6)
            allString = allString + "," + sid6
        }

        if (sid7 != null) {
            param['sid7'] = sid7
            if (!hasDouble) hasDouble = allString.includes(sid7)
            allString = allString + "," + sid7
        }

        if (sid8 != null) {
            param['sid8'] = sid8
            if (!hasDouble) hasDouble = allString.includes(sid8)
            allString = allString + "," + sid8
        }

        if (sid9 != null) {
            param['sid9'] = sid9
            if (!hasDouble) hasDouble = allString.includes(sid9)
            allString = allString + "," + sid9
        }

        if (sid10 != null) {
            param['sid10'] = sid10
            if (!hasDouble) hasDouble = allString.includes(sid10)
            allString = allString + "," + sid10
        }

        if (sid11 != null) {
            param['sid11'] = sid11
            if (!hasDouble) hasDouble = allString.includes(sid11)
            allString = allString + "," + sid11
        }

        if (sid12 != null) {
            param['sid12'] = sid12
            if (!hasDouble) hasDouble = allString.includes(sid12)
            allString = allString + "," + sid12
        }

        if (sid13 != null) {
            param['sid13'] = sid13
            if (!hasDouble) hasDouble = allString.includes(sid13)
            allString = allString + "," + sid13
        }

        if (sid14 != null) {
            param['sid14'] = sid14
            if (!hasDouble) hasDouble = allString.includes(sid14)
            allString = allString + "," + sid14
        }
        if (sid15 != null) {
            param['sid15'] = sid15
            if (!hasDouble) hasDouble = allString.includes(sid15)
            allString = allString + "," + sid15
        }
        if (sid16 != null) {
            param['sid16'] = sid16
            if (!hasDouble) hasDouble = allString.includes(sid16)
            allString = allString + "," + sid16
        }
        if (sid17 != null) {
            param['sid17'] = sid17
            if (!hasDouble) hasDouble = allString.includes(sid17)
            allString = allString + "," + sid17
        }
        if (sid18 != null) {
            param['sid18'] = sid18
            if (!hasDouble) hasDouble = allString.includes(sid18)
            allString = allString + "," + sid18
        }
        if (sid19 != null) {
            param['sid19'] = sid19
            if (!hasDouble) hasDouble = allString.includes(sid19)
            allString = allString + "," + sid19
        }
        if (sid20 != null) {
            param['sid20'] = sid20
            if (!hasDouble) hasDouble = allString.includes(sid20)
            allString = allString + "," + sid20
        }

        if (!hasDouble) {
            if (localStorage.getItem("relative_lic") != null) {
                param['lic'] = localStorage.getItem("relative_lic")
            }

            fetch("/relative/v1/custom/modify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.text())
                .then(
                    result => {
                        // if (result!= null && result.status === 0) {
                        setOpen(false);
                        if (props.close != null) props.close(false)
                        if (props.refresh != null) props.refresh()
                        // }
                    }
                );
        } else {
            setAlertMsg("比較的股票不得重複")
            setShowAlert(true)
        }
    };

    const setDD = (value1, setDD1) => {
        let s1 = props.row.desc.find(r => r.sid === value1)
        if (s1 != null) setDD1({ value: value1, label: value1 + " " + s1.name })
    }

    useEffect(() => {
        if (props.open) {
            if (props.row != null) {
                setSystem(props.row.name)

                if (props.row.sid1 != null) {
                    setSID1(props.row.sid1)
                    setDD(props.row.sid1, setSID1v)
                }
                if (props.row.sid2 != null) {
                    setSID2(props.row.sid2)
                    setDD(props.row.sid2, setSID2v)
                }
                if (props.row.sid3 != null) {
                    setSID3(props.row.sid3)
                    setDD(props.row.sid3, setSID3v)
                }
                if (props.row.sid4 != null) {
                    setSID4(props.row.sid4)
                    setDD(props.row.sid4, setSID4v)
                }
                if (props.row.sid5 != null) {
                    setSID5(props.row.sid5)
                    setDD(props.row.sid5, setSID5v)
                }
                if (props.row.sid6 != null) {
                    setSID6(props.row.sid6)
                    setDD(props.row.sid6, setSID6v)
                }
                if (props.row.sid7 != null) {
                    setSID7(props.row.sid7)
                    setDD(props.row.sid7, setSID7v)
                }
                if (props.row.sid8 != null) {
                    setSID8(props.row.sid8)
                    setDD(props.row.sid8, setSID8v)
                }
                if (props.row.sid9 != null) {
                    setSID9(props.row.sid9)
                    setDD(props.row.sid9, setSID9v)
                }
                if (props.row.sid10 != null) {
                    setSID10(props.row.sid10)
                    setDD(props.row.sid10, setSID10v)
                }
                if (props.row.sid11 != null) {
                    setSID11(props.row.sid11)
                    setDD(props.row.sid11, setSID11v)
                }
                if (props.row.sid12 != null) {
                    setSID12(props.row.sid12)
                    setDD(props.row.sid12, setSID12v)
                }
                if (props.row.sid13 != null) {
                    setSID13(props.row.sid13)
                    setDD(props.row.sid13, setSID13v)
                }
                if (props.row.sid14 != null) {
                    setSID14(props.row.sid14)
                    setDD(props.row.sid14, setSID14v)
                }
                if (props.row.sid15 != null) {
                    setSID15(props.row.sid15)
                    setDD(props.row.sid15, setSID15v)
                }
                if (props.row.sid16 != null) {
                    setSID16(props.row.sid16)
                    setDD(props.row.sid16, setSID16v)
                }
                if (props.row.sid17 != null) {
                    setSID17(props.row.sid17)
                    setDD(props.row.sid17, setSID17v)
                }
                if (props.row.sid18 != null) {
                    setSID18(props.row.sid18)
                    setDD(props.row.sid18, setSID18v)
                }
                if (props.row.sid19 != null) {
                    setSID19(props.row.sid19)
                    setDD(props.row.sid19, setSID19v)
                }
                if (props.row.sid20 != null) {
                    setSID20(props.row.sid20)
                    setDD(props.row.sid20, setSID20v)
                }

            }
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    修改比較公司
                </DialogTitle>

                <DialogContent sx={{ p: 3 }}>

                    <Stack spacing={2} direction="column" sx={{ mt: 2 }}>

                        <TextField
                            size="medium"
                            id="name"
                            label="名稱"
                            value={system}
                            onChange={event => setSystem(event.target.value)}
                        >
                        </TextField>
                        <SearchCompany setSID={setSID1} bidValue={sid1v} />
                        <SearchCompany setSID={setSID2} bidValue={sid2v} />
                        <SearchCompany setSID={setSID3} bidValue={sid3v} />
                        <SearchCompany setSID={setSID4} bidValue={sid4v} />
                        <SearchCompany setSID={setSID5} bidValue={sid5v} />
                        <SearchCompany setSID={setSID6} bidValue={sid6v} />
                        <SearchCompany setSID={setSID7} bidValue={sid7v} />
                        <SearchCompany setSID={setSID8} bidValue={sid8v} />
                        <SearchCompany setSID={setSID9} bidValue={sid9v} />
                        <SearchCompany setSID={setSID10} bidValue={sid10v} />
                        <SearchCompany setSID={setSID11} bidValue={sid11v} />
                        <SearchCompany setSID={setSID12} bidValue={sid12v} />
                        <SearchCompany setSID={setSID13} bidValue={sid13v} />
                        <SearchCompany setSID={setSID14} bidValue={sid14v} />
                        <SearchCompany setSID={setSID15} bidValue={sid15v} />
                        <SearchCompany setSID={setSID16} bidValue={sid16v} />
                        <SearchCompany setSID={setSID17} bidValue={sid17v} />
                        <SearchCompany setSID={setSID18} bidValue={sid18v} />
                        <SearchCompany setSID={setSID19} bidValue={sid19v} />
                        <SearchCompany setSID={setSID20} bidValue={sid20v} />
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button sx={{ width: 80 }} onClick={handleClose}>取消</Button>
                    <Button sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        修改
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
