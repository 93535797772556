import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import SellDialog from './SellDialog'
import AlertD from '../util/AlertD'
import BuyRuleDialog from './BuyRuleDialog'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import BuyDialog2 from './BuyDialog2'
import BuyDialog3 from './BuyDialog3'
import DetailKChart from '../rec/DetailKChart'
import ButtonGroup from '@mui/material/ButtonGroup';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoveSetting from './LoveSetting';
import ConfirmD from '../util/ConfirmD';
import TranDetail from './TranDetail';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 15,
        whiteSpace: "nowrap",
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function LoveListTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [showAlert, setShowAlert] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);

    const [row, setRow] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const [age, setAge] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const del = () => {
        setAnchorEl(null);
        setAlertMsg("確定刪除?")
        setShowConfirm(true)
    }

    const delOk = () => {
        setShowConfirm(false)

        let param = {
            sn: getId(),
            id: row.id
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }


        fetch("/relative/v1/love/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    fetchData()
                }
            );

    }

    const fetchData = () => {
        setLoading(true)

        let param = {
            page: page,
            sn: getId(),
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }

        param = putInpQueryParam(param)

        fetch("/relative/v1/love/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.love1 != null && result.love1.length > 0) {
                            // setTotal(result.total_page)
                            // setTotal(0)
                            setResult(result.love1)
                            // setBuyNum(result.count)
                        } else {
                            // setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, [page]);

    const openDetail = (row) => {
        setAnchorEl(null);
        setOpenSetting(true)
    }

    const openDetail3 = (id) => {
        setSid(id)
        setOpenSetting3(true)
    }



    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setRow(row)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const getTPString = (tp) => {
        if (tp === 1) return "本益比"
        else if (tp === 2) return "股價淨值比"
        else if (tp === 3) return "股價營收比"
        else if (tp === 4) return "EV / 營收"
        else if (tp === 5) return "EV / 資本"
        else if (tp === 6) return "EV / EBITDA"
        else return ""
    }

    const getGroupString = (tp) => {
        if (tp === -1) return "產業別"
        else if (tp === -2) return "自訂類別"
        else if (tp === 150) return "系統分類Ⅰ"
        else if (tp === 200) return "系統分類Ⅱ"
        else if (tp === 250) return "系統分類Ⅲ"
        else return ""
    }

    const getIndexString = (row) => {
        if (row.group === -1) return row.index
        else if (row.group === -2) return row.index_name
        else {
            return row.index + "號分類"
        }

    }


    const getLevelColor = (row) => {
        if (row.score > 1) return "success"
        else if (row.score < 1) return "error"
        else return null
    }

    const getLevelWord = (row) => {
        if (row.level != null) {
            if (row.level === 0) return "大幅低估"
            if (row.level === 1) return "稍微低估"
            else if (row.level === 2) return "大幅高估"
            else if (row.level === 3) return "稍微高估"
            else return ""
        } else return ""
    }

    const getLevelVariant = (row) => {
        if (row.level != null) {
            if (row.level === 0) return "contained"
            if (row.level === 1) return "outlined"
            else if (row.level === 2) return "contained"
            else if (row.level === 3) return "outlined"
            else return "outlined"
        } else return "outlined"
    }

    const getRowValue = (tp, row) => {
        if (tp === 1) {
            return row.per != null ? row.per.toFixed(2) : "-"
        } else if (tp === 2) {
            return row.pb != null ? row.pb.toFixed(2) : "-"
        } else if (tp === 3) {
            return row.price_rev != null ? row.price_rev.toFixed(2) : "-"
        } else if (tp === 4) {
            return row.ev_rev != null ? row.ev_rev.toFixed(2) : "-"
        } else if (tp === 5) {
            return row.ev_capital != null ? row.ev_capital.toFixed(2) : "-"
        } else if (tp === 6) {
            return row.ev_ebitda != null ? row.ev_ebitda.toFixed(2) : "-"
        } else {
            return ""
        }
    }

    return (
        <>
            {openSetting ? <BuyDialog3 row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></BuyDialog3> : null}
            {openSetting1 ? <BuyRuleDialog row={row} refresh={fetchData} open={openSetting1} close={setOpenSetting1}></BuyRuleDialog> : null}
            {openSetting2 ? <BuyDialog2 row={row} refresh={fetchData} open={openSetting2} close={setOpenSetting2}></BuyDialog2> : null}
            {openSetting3 && <DetailKChart sid={sid} open={openSetting3} close={setOpenSetting3} />}
            {openSetting4 && <TranDetail row={row} sid={sid} open={openSetting4} close={setOpenSetting4} />}

            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {showConfirm ? <ConfirmD desc={alertMsg} open={showConfirm} close={setShowConfirm} ok={delOk}></ConfirmD> : null}


            <Box sx={{ p: 0, width: "100%", overflow: 'hidden' }}>
                <Typography sx={{ mt: 3, textAlign: "center" }} variant="h4">我的最愛
                    {/* <IconButton onClick={e => setOpenSetting2(true)}>
                        <AddIcon />
                    </IconButton> */}

                </Typography>
                <Typography fontSize={12} sx={{ width: "100%", textAlign: "center" }} >目前僅能從相對表新增我的最愛，最多能存放8筆。</Typography>


                <TableContainer sx={{ position: 'absolute', top: 80 + Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, mt: 1.5 }}>
                    <Table sx={{}} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell sx={{ zIndex: 999, position: "sticky", left: 0 }}>股票</StyledTableCell>
                                <StyledTableCell>類股</StyledTableCell>
                                <StyledTableCell>倍數</StyledTableCell>
                                <StyledTableCell align="center">狀態</StyledTableCell>
                                <StyledTableCell align="center">倍數預估值</StyledTableCell>
                                <StyledTableCell align="center">倍數實際值</StyledTableCell>
                                <StyledTableCell align="center">大分類</StyledTableCell>
                                <StyledTableCell align="center">小分類</StyledTableCell>

                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => {
                                return <StyledTableRow key={row.id}>

                                    <StyledTableCell
                                        sx={{ zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[50] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }}
                                        onClick={e => openDetail2(row.company.stock_no)} >
                                        {row.company.short_name} {row.company.stock_no}

                                        <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{}} >
                                        {row.company.inductry}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{}} >
                                        {getTPString(row.tp)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button size="small" variant={getLevelVariant(row.egr)} disableElevation fullWidth color={getLevelColor(row.egr)}>{getLevelWord(row.egr)}</Button>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{}} >
                                        {row.egr.predict.toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{}} >
                                        {getRowValue(row.tp, row.egr)}
                                    </StyledTableCell>

                                    <StyledTableCell align="center" sx={{}} >
                                        {getGroupString(row.group)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{}} >
                                        {getIndexString(row)}
                                    </StyledTableCell>




                                    <StyledTableCell align="center">
                                        <IconButton sx={{}} onClick={e => handleMenuClick(e, row)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            })}
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenuClose}
                            >

                                {/* <MenuItem onClick={e => openDetail()}>修改</MenuItem> */}
                                <MenuItem onClick={e => del()}>刪除</MenuItem>
                            </Menu>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </>
    );
}
