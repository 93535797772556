import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import SearchCompany from '../love/SearchCompany'

export default function BuyDialog2(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [createDate, setCreateDate] = React.useState(dayjs(new Date()));
    const [dir, setDirection] = React.useState("0");
    const [system, setSystem] = React.useState(null);
    const [sid1, setSID1] = React.useState(null);
    const [sid2, setSID2] = React.useState(null);
    const [sid3, setSID3] = React.useState(null);
    const [sid4, setSID4] = React.useState(null);
    const [sid5, setSID5] = React.useState(null);
    const [sid6, setSID6] = React.useState(null);
    const [sid7, setSID7] = React.useState(null);
    const [sid8, setSID8] = React.useState(null);
    const [sid9, setSID9] = React.useState(null);
    const [sid10, setSID10] = React.useState(null);
    const [sid11, setSID11] = React.useState(null);
    const [sid12, setSID12] = React.useState(null);
    const [sid13, setSID13] = React.useState(null);
    const [sid14, setSID14] = React.useState(null);
    const [sid15, setSID15] = React.useState(null);
    const [sid16, setSID16] = React.useState(null);
    const [sid17, setSID17] = React.useState(null);
    const [sid18, setSID18] = React.useState(null);
    const [sid19, setSID19] = React.useState(null);
    const [sid20, setSID20] = React.useState(null);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    // sn : String, id : String, ymd : String, dir : Int = 0, setting : TurtleLoveSetting
    const handleOKClose = () => {
        let param = {
            sn: getId(),
            name: system
        }

        let allString = ""
        let hasDouble = false
        let count = 0

        if (sid1 != null) {
            param['sid1'] = sid1
            allString = allString + "," + sid1
            count = count + 1
        }

        if (sid2 != null) {
            param['sid2'] = sid2
            if (!hasDouble) hasDouble = allString.includes(sid2)
            allString = allString + "," + sid2
            count = count + 1
        }
        if (sid3 != null) {
            param['sid3'] = sid3
            if (!hasDouble) hasDouble = allString.includes(sid3)
            allString = allString + "," + sid3
            count = count + 1
        }

        if (sid4 != null) {
            param['sid4'] = sid4
            if (!hasDouble) hasDouble = allString.includes(sid4)
            allString = allString + "," + sid4
            count = count + 1
        }

        if (sid5 != null) {
            param['sid5'] = sid5
            if (!hasDouble) hasDouble = allString.includes(sid5)
            allString = allString + "," + sid5
            count = count + 1
        }

        if (sid6 != null) {
            param['sid6'] = sid6
            if (!hasDouble) hasDouble = allString.includes(sid6)
            allString = allString + "," + sid6
            count = count + 1
        }

        if (sid7 != null) {
            param['sid7'] = sid7
            if (!hasDouble) hasDouble = allString.includes(sid7)
            allString = allString + "," + sid7
            count = count + 1
        }

        if (sid8 != null) {
            param['sid8'] = sid8
            if (!hasDouble) hasDouble = allString.includes(sid8)
            allString = allString + "," + sid8
            count = count + 1
        }

        if (sid9 != null) {
            param['sid9'] = sid9
            if (!hasDouble) hasDouble = allString.includes(sid9)
            allString = allString + "," + sid9
            count = count + 1
        }

        if (sid10 != null) {
            param['sid10'] = sid10
            if (!hasDouble) hasDouble = allString.includes(sid10)
            allString = allString + "," + sid10
            count = count + 1
        }

        if (sid11 != null) {
            param['sid11'] = sid11
            if (!hasDouble) hasDouble = allString.includes(sid11)
            allString = allString + "," + sid11
            count = count + 1
        }

        if (sid12 != null) {
            param['sid12'] = sid12
            if (!hasDouble) hasDouble = allString.includes(sid12)
            allString = allString + "," + sid12
            count = count + 1
        }

        if (sid13 != null) {
            param['sid13'] = sid13
            if (!hasDouble) hasDouble = allString.includes(sid13)
            allString = allString + "," + sid13
            count = count + 1
        }

        if (sid14 != null) {
            param['sid14'] = sid14
            if (!hasDouble) hasDouble = allString.includes(sid14)
            allString = allString + "," + sid14
            count = count + 1
        }
        if (sid15 != null) {
            param['sid15'] = sid15
            if (!hasDouble) hasDouble = allString.includes(sid15)
            allString = allString + "," + sid15
            count = count + 1
        }
        if (sid16 != null) {
            param['sid16'] = sid16
            if (!hasDouble) hasDouble = allString.includes(sid16)
            allString = allString + "," + sid16
            count = count + 1
        }

        if (sid17 != null) {
            param['sid17'] = sid17
            if (!hasDouble) hasDouble = allString.includes(sid17)
            allString = allString + "," + sid17
            count = count + 1
        }

        if (sid18 != null) {
            param['sid18'] = sid18
            if (!hasDouble) hasDouble = allString.includes(sid18)
            allString = allString + "," + sid18
            count = count + 1
        }

        if (sid19 != null) {
            param['sid19'] = sid19
            if (!hasDouble) hasDouble = allString.includes(sid19)
            allString = allString + "," + sid19
            count = count + 1
        }

        if (sid20 != null) {
            param['sid20'] = sid20
            if (!hasDouble) hasDouble = allString.includes(sid20)
            allString = allString + "," + sid20
            count = count + 1
        }

        if (hasDouble) {
            setAlertMsg("比較的股票不得重複")
            setShowAlert(true)
        } else if (count < 7) {
            setAlertMsg("至少要選擇7支股票以上")
            setShowAlert(true)
        } else {
            if (localStorage.getItem("relative_lic") != null) {
                param['lic'] = localStorage.getItem("relative_lic")
            }

            fetch("/relative/v1/custom/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.text())
                .then(
                    result => {
                        // if (result!= null && result.status === 0) {
                        setOpen(false);
                        if (props.close != null) props.close(false)
                        if (props.refresh != null) props.refresh()
                        // }
                    }
                );
        }
    };


    useEffect(() => {
        if (props.open) {
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    新增比較公司
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    <Typography variant="caption">至少需要7間公司才能比較。</Typography>
                    {/* <Stack direction={"column"}>
                        <Typography variant="caption">1. 新增後可以到相對表，大分類為自訂分類中找到。</Typography>
                        
                    </Stack> */}

                    <Stack spacing={2} direction="column" sx={{ mt: 2 }}>

                        <TextField
                            size="medium"
                            id="name"
                            label="名稱"
                            required
                            value={system}
                            onChange={event => setSystem(event.target.value)}
                        >
                        </TextField>
                        <SearchCompany setSID={setSID1} />
                        <SearchCompany setSID={setSID2} />
                        <SearchCompany setSID={setSID3} />
                        <SearchCompany setSID={setSID4} />
                        <SearchCompany setSID={setSID5} />
                        <SearchCompany setSID={setSID6} />
                        <SearchCompany setSID={setSID7} />
                        <SearchCompany setSID={setSID8} />
                        <SearchCompany setSID={setSID9} />
                        <SearchCompany setSID={setSID10} />
                        <SearchCompany setSID={setSID11} />
                        <SearchCompany setSID={setSID12} />
                        <SearchCompany setSID={setSID13} />
                        <SearchCompany setSID={setSID14} />
                        <SearchCompany setSID={setSID15} />
                        <SearchCompany setSID={setSID16} />
                        <SearchCompany setSID={setSID17} />
                        <SearchCompany setSID={setSID18} />
                        <SearchCompany setSID={setSID19} />
                        <SearchCompany setSID={setSID20} />

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button sx={{ }} onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"} sx={{  }} onClick={handleOKClose} autoFocus>
                        {localStorage.getItem("noad") === "1" ? "確定" : "確定（VIP專屬）"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
