import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId } from '../util/ID'
import dayjs from 'dayjs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { green, red } from '@mui/material/colors';
import DetailKChart from '../rec/DetailKChart'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function CompareCompany(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [createDate, setCreateDate] = React.useState(dayjs(new Date()));
    const [dir, setDirection] = React.useState("0");
    const [sid, setSid] = React.useState(null);
    const [system, setSystem] = React.useState("0");
    const [result, setResult] = React.useState(null);
    const [openSetting2, setOpenSetting2] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }
    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    // sn : String, id : String, ymd : String, dir : Int = 0, setting : TurtleLoveSetting
    const get = () => {
        let param = {
            sn: getId(),
            tp: props.tp,
            index: props.index,
            group: props.group
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }

        fetch("/relative/v1/app/getCompareCompany", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.text())
            .then(
                result => {
                    if (result != null) {
                        if (result.data != null && result.data.length > 0) {
                            setResult(result.data)
                        }
                    }
                }
            );
    };


    useEffect(() => {
        if (props.open) {
            setOpen(true)
            get()
        } else setOpen(false)
    }, [props.open]);

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }    

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}

            
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    比較公司
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    <TableContainer sx={{  }} >
                        <Table sx={{}} stickyHeader size="small">
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center">{dir === "0" ? "低估排名" : "高估排名"}</StyledTableCell>
                                    <StyledTableCell>股票</StyledTableCell>
                                    <StyledTableCell >類股</StyledTableCell>
                                    <StyledTableCell align="center">比較公司</StyledTableCell>

                                    {/* <StyledTableCell align="center">狀態</StyledTableCell> */}
                                    <StyledTableCell align="right">本益比</StyledTableCell>
                                    <StyledTableCell align="right">股價淨值比</StyledTableCell>
                                    <StyledTableCell align="right">成長率</StyledTableCell>
                                    {/* <StyledTableCell align="right">波動金額</StyledTableCell> */}
                                    <StyledTableCell align="right">風險</StyledTableCell>
                                    <StyledTableCell align="right">股息發放率</StyledTableCell>
                                    <StyledTableCell align="center">動作</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result != null && result.map((row) => (
                                    <StyledTableRow key={row.company.stock_no}>
                                        <StyledTableCell align="center" sx={{}} >
                                            {row.index}
                                        </StyledTableCell>
                                        <StyledTableCell onClick={e => openDetail2(row.company.stock_no)} >
                                            {row.company.short_name} {row.company.stock_no}

                                            <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                                <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                                <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{}} >
                                            {row.company.inductry}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{}} >
                                            <Button>顯示公司 - {row.compare_size}間</Button>

                                        </StyledTableCell>
                                        {/* <StyledTableCell align="center">
                                        <Button variant="outlined" disableElevation fullWidth color={getLevelColor(row)}>{getLevelWord(row)}</Button>
                                    </StyledTableCell> */}
                                        <StyledTableCell align="right">
                                            {row.per.toFixed(2)}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.pb != null ? row.pb.toFixed(2) : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {(row.inc_rate * 100).toFixed(2)}%
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {(row.risk).toFixed(2)}%
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {(row.int_send_rate * 100).toFixed(2)}%
                                        </StyledTableCell>


                                        {/* <StyledTableCell align="right">{row.risk}%</StyledTableCell> */}
                                        <StyledTableCell align="center">
                                            <Button color="inherit" startIcon={<FavoriteIcon />} disableElevation variant='contained' sx={{
                                            }}>加入交易</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </DialogContent>
                <DialogActions>
                    <Button sx={{ width: 80 }} onClick={handleClose}>取消</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
