import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import { Adsense } from '@ctrl/react-adsense';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header + 10, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <Box sx={{ pl: 2, pr: 2 }}>

                <Typography sx={{ mt: 2, textAlign: "center" }} variant='h4' fontWeight={"bold"}>軟體說明</Typography>
                <Typography sx={{ mt: 2 }} variant='body1' >相對評價法是從"當代財經大師的估值課"或"如何評價一支股票"書本的相對估值章節，針對台灣股市設計的一套投資軟體。下面會針對一些重要表格與操作來說明，若需要更詳盡的解說或原理的解釋，可以直接購買此書獲得解答。</Typography>
                {/* <img src='' style={{ width: "500px" }} /> */}

                <Box
                    component="img"
                    sx={{
                        height: 233,
                        width: 150,
                        // maxHeight: { xs: '40vh', md: '70vh' },
                        // maxWidth: { xs: '40wh', md: '70wh' },
                    }}
                    alt="book"
                    src="book.jpg"
                />

                <Box
                    component="img"
                    sx={{
                        height: 233,
                        width: 150,
                        // maxHeight: { xs: '40vh', md: '70vh' },
                        // maxWidth: { xs: '40wh', md: '70wh' },
                    }}
                    alt="book1"
                    src="book1.jpg"
                />

                {localStorage.getItem('noad') !== "1" && <Adsense
                    client="ca-pub-8613274553211553"
                    slot="4386477039"
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                />}


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>相對表</Typography>

                <Typography sx={{ mt: 0 }} variant='body1' >相對表是顯示一群公司比較過後的結果。將倍數與倍數的主要變量利用遞迴做出調整之後，在與實際的值比較是否有低估或高估。</Typography>

                <Stack direction={"column"} spacing={0.2} sx={{ p: 2 }}>
                    <Typography fontWeight={"bold"}>倍數</Typography>
                    <Typography variant='body1'>針對哪個倍數來當作比較的基準。</Typography>
                    <Typography variant='body1'>■ 本益比：股價／近四季EPS。</Typography>
                    <Typography variant='body1'>■ 股價淨值比：股價／近一季淨值。</Typography>
                    <Typography variant='body1'>■ 股價營收比：股價／近12個月營收。</Typography>
                    <Typography variant='body1'>■ EV：市值+負債-現金。</Typography>
                    <Typography variant='body1'>■ EV／營收：EV／近12個月營收。</Typography>
                    <Typography variant='body1'>■ 資本：資產-現金。</Typography>
                    <Typography variant='body1'>■ EV／資本：EV／近一季資本。</Typography>
                    <Typography variant='body1'>■ EBITDA：稅後淨利 + 稅金 + 利息 + 折舊 + 攤銷。</Typography>
                    <Typography variant='body1'>■ EV／EBITDA：EV／近四季EBITDA。</Typography>
                </Stack>

                <Stack direction={"column"} spacing={0.2} sx={{ p: 2 }}>
                    <Typography fontWeight={"bold"}>大類別 / 小類別</Typography>
                    <Typography variant='body1'>■ 產業別：利用產業將公司分群來做比較。</Typography>
                    <Typography variant='body1'>■ 自訂類別：可以自行挑選要比較的公司。</Typography>
                    <Typography variant='body1'>■ 系統分類Ⅰ：利用機器學習的演算法將現金流，風險，成長相類似的公司分群來做比較。</Typography>
                    <Typography variant='body1'>■ 系統分類Ⅱ：利用機器學習的演算法將現金流，風險，成長相類似的公司分群來做比較。</Typography>
                    <Typography variant='body1'>■ 系統分類Ⅲ：利用機器學習的演算法將現金流，風險，成長相類似的公司分群來做比較。</Typography>
                </Stack>



                {/* <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>設定</Typography>
                <Typography variant='body1'>可以藉由設定重新計算每個單位的價值與加入其他過濾條件。</Typography>

                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>■ 名目帳戶：總資金</Typography>
                    <Typography variant='body1'>■ 帳戶%：名目帳戶百分之多少當作上漲或下跌1個單位的價值。</Typography>
                    <Typography variant='body1'>■ ATR：20日平均真實範圍（Average True Range），用海龜的ATR公式，不用現在的公式。</Typography>
                    <Typography variant='body1'>■ 其他：其他股票過濾規則。</Typography>
                </Stack> */}



            </Box>
        </Box>
    );
}
