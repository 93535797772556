import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';
import AllStrageRules from '../strage/AllStrageRules'

export default function RuleDialog1(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [capital, setCapital] = React.useState(localStorage.getItem("relative_capital") != null ? localStorage.getItem("relative_capital") : 1000000);
    const [risk, setRisk] = React.useState(localStorage.getItem("relative_risk") != null ? localStorage.getItem("relative_risk") : 1);
    const [atr, setATR] = React.useState(localStorage.getItem("relative_atr") != null ? localStorage.getItem("relative_atr") : 20);
    const [nowRows, setNowRows] = React.useState(localStorage.getItem("relative_search_rules" + props.dir) != null ? JSON.parse(localStorage.getItem("relative_search_rules" + props.dir)) : [])

    const clearRules = () => {
        setNowRows([]);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (capital != "" && capital != null) localStorage.setItem("relative_capital", capital)
        if (risk != "" && risk != null) localStorage.setItem("relative_risk", risk)
        if (atr != "" && atr != null) localStorage.setItem("relative_atr", atr)

        if (nowRows != null && nowRows.length > 0) localStorage.setItem("relative_search_rules" + props.dir, JSON.stringify(nowRows))
        else localStorage.removeItem("relative_search_rules" + props.dir)

        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    設定
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 0 }}>資金配置</Typography>
                    <Stack spacing={3} direction="column" sx={{ mt: 2, mb: 2 }}>
                        <TextField
                            value={capital}
                            onChange={event => setCapital(event.target.value)}
                            inputProps={{}}
                            size="medium" label="名目帳戶" fullWidth InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}></TextField>


                        <TextField
                            value={risk}
                            onChange={event => setRisk(event.target.value)}
                            size="medium" label="帳戶%" fullWidth
                            helperText="每單位價值。帳戶的多少百分比。預設：1%"
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}></TextField>

                        <TextField size="medium" label="ATR" fullWidth
                            value={atr}
                            onChange={event => setATR(event.target.value)}
                            helperText="平均真實區間。預設20日"
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                        ></TextField>
                    </Stack>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={clearRules}>清除規則</Button>
                    <Button sx={{ width: 80 }} onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"} sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
