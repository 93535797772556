import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { Button, Chip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { green, red, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import BuyDialog from './BuyDialog'
import AlertD from '../util/AlertD'
import RuleDialog1 from './RuleDialog1'
import DetailKChart from '../rec/DetailKChart'
import StockHistoryDialog from './StockHistoryDialog';
import StrageDialog from '../strage/StrageDialog';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareCompany from './CompareCompany';
import { Adsense } from '@ctrl/react-adsense';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function InsTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [buyNum, setBuyNum] = React.useState(0)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);
    const [openSetting5, setOpenSetting5] = React.useState(false);

    const [system, setSystem] = React.useState("1");
    const [dir, setDir] = React.useState("0");
    const [sid, setSid] = React.useState(null);
    const [row, setRow] = React.useState(null);
    const [age, setAge] = React.useState("");
    const [group, setGroup] = React.useState(-1);
    const [index, setIndex] = React.useState(null);
    const [groupIndex, setGroupIndex] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchDataInter(value)
    };

    const fetchInitData = () => {
        setPage(1)
        fetchDataInter(1)
    }

    const fetchDataInter = (pv) => {
        setLoading(true)

        let rules = localStorage.getItem("relative_search_rules" + dir) != null ? JSON.parse(localStorage.getItem("relative_search_rules" + dir)) : []

        let param = {
            group: group,
            sn: getId(),
            sort: dir,
            tp: system,
            page: pv,
            rules: rules,
            inp: {}
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }

        if (index != null) {
            param['index'] = index
        }

        param = putInpQueryParam(param)


        fetch("/relative/v1/app/getCompareCompany", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.data != null) {
                            setTotal(result.total)
                            setResult(result.data)
                            setBuyNum(result.count)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }


    const fetchMenu = (g) => {
        let param = {
            group: g,
            sn: getId(),
            sort: dir,
            tp: system,
            inp: {}
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }

        param = putInpQueryParam(param)

        fetch("/relative/v1/app/getMenu", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setGroupIndex(result.menu)
                        setTotal(0)
                        setResult([])
                    }
                }
            );
    }

    useEffect(() => {
        if (groupIndex == null) fetchMenu(group)
        else if (index != null && system != null && group != null) fetchInitData()
    }, [system, index]);

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }


    const clickLove = (row) => {
        setLoading(true)

        let param = {
            sn: getId(),
            sid: row.company.stock_no,
            group: group,
            tp: system,
            index: index
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }

        fetch("/relative/v1/love/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.text())
            .then(
                result => {
                    setLoading(false)
                    if (result != null && result.status === 0) {
                        setAlertMsg("新增成功")
                        setShowAlert(true)
                    }
                }
            );
    }


    const handleChange = (event) => {
        if (localStorage.getItem("noad") === "1" || event.target.value === "1" || event.target.value === "2")
            setSystem(event.target.value)
    };


    const handleGroupChange = (event) => {
        if (localStorage.getItem("noad") === "1" || event.target.value === -1) {
            setIndex(null)
            setGroup(event.target.value);
            fetchMenu(event.target.value)
        }

    };

    const handleIndexChange = (event) => {
        setIndex(event.target.value);
    };


    const getLevelColor = (row) => {
        if (row.score > 1) return "success"
        else if (row.score < 1) return "error"
        else return null
    }

    const getLevelWord = (row) => {
        if (row.level != null) {
            if (row.level === 0) return "大幅低估"
            if (row.level === 1) return "稍微低估"
            else if (row.level === 2) return "大幅高估"
            else if (row.level === 3) return "稍微高估"
            else return ""
        } else return ""
    }

    const getLevelVariant = (row) => {
        if (row.level != null) {
            if (row.level === 0) return "contained"
            if (row.level === 1) return "outlined"
            else if (row.level === 2) return "contained"
            else if (row.level === 3) return "outlined"
            else return "outlined"
        } else return "outlined"
    }

    const getName = (row) => {
        if (group === -1) {
            return row.index + "【" + row.size + "】"
        } else if (group === -2) {
            return row.name + "【" + row.size + "】"
        } else return "" + row.index + "號分類【" + row.size + "間】"
    }

    const getColumn = () => {
        if (system === "1") {
            return ["預估本益比", "本益比", "預期成長率(同伴)", "股價標準差", "股息發放率"]
        } else if (system === "2") {
            return ["預估股價淨值比", "股價淨值比", "股東權益報酬率(同伴)", "預期成長率", "股價標準差", "股息發放率"]
        } else if (system === "3") {
            return ["預估股價營收比", "股價營收比", "淨利率(同伴)", "預期成長率", "股價標準差", "股息發放率"]
        } else if (system === "4") {
            return ["預估EV/營收", "EV/營收", "營業利益率(同伴)", "預期成長率", "再投資率", "股價標準差"]
        } else if (system === "5") {
            return ["預估EV/資本", "EV/資本", "資本報酬率(同伴)", "預期成長率", "再投資率", "股價標準差"]
        } else if (system === "6") {
            return ["預估EV/EBITDA", "EV/EBITDA", "再投資率(同伴)", "預期成長率", "股價標準差", "資本報酬率", "稅率"]
        } else {
            return ["預估本益比", "本益比", "預期成長率(同伴)", "股價標準差", "股息發放率"]
        }
    }

    const getRowValue = (row) => {
        if (system === "1") {
            let per = row.per != null ? row.per.toFixed(2) : "-"
            let incRate = row.inc_rate != null ? (row.inc_rate * 100).toFixed(2) + "%" : "-"
            let risk = row.risk != null ? (row.risk).toFixed(2) + "%" : "-"
            let intSendRate = row.int_send_rate != null ? (row.int_send_rate * 100).toFixed(2) + "%" : ""
            return [row.predict.toFixed(2), per, incRate, risk, intSendRate]
        } else if (system === "2") {
            let pb = row.pb != null ? row.pb.toFixed(2) : "-"
            let incRate = row.inc_rate != null ? (row.inc_rate * 100).toFixed(2) + "%" : "-"
            let risk = row.risk != null ? (row.risk).toFixed(2) + "%" : "-"
            let intSendRate = row.int_send_rate != null ? (row.int_send_rate * 100).toFixed(2) + "%" : ""
            let roe = row.roe != null ? (row.roe * 100).toFixed(2) + "%" : ""

            return [row.predict.toFixed(2), pb, roe, incRate, risk, intSendRate]
        } else if (system === "3") {
            let price_rev = row.price_rev != null ? row.price_rev.toFixed(2) : "-"
            let incRate = row.inc_rate != null ? (row.inc_rate * 100).toFixed(2) + "%" : "-"
            let risk = row.risk != null ? (row.risk).toFixed(2) + "%" : "-"
            let intSendRate = row.int_send_rate != null ? (row.int_send_rate * 100).toFixed(2) + "%" : ""
            let txa = row.txa != null ? (row.txa).toFixed(2) + "%" : ""

            return [row.predict.toFixed(2), price_rev, txa, incRate, risk, intSendRate]
        } else if (system === "4") {
            let ev_rev = row.ev_rev != null ? row.ev_rev.toFixed(2) : "-"
            let incRate = row.inc_rate != null ? (row.inc_rate * 100).toFixed(2) + "%" : "-"
            let risk = row.risk != null ? (row.risk).toFixed(2) + "%" : "-"
            let invRate = row.inv_rate != null ? (row.inv_rate * 100).toFixed(2) + "%" : ""
            let oi = row.oi != null ? (row.oi).toFixed(2) + "%" : ""

            return [row.predict.toFixed(2), ev_rev, oi, incRate, invRate, risk]
        } else if (system === "5") {
            let ev_capital = row.ev_capital != null ? row.ev_capital.toFixed(2) : "-"
            let incRate = row.inc_rate != null ? (row.inc_rate * 100).toFixed(2) + "%" : "-"
            let risk = row.risk != null ? (row.risk).toFixed(2) + "%" : "-"
            let invRate = row.inv_rate != null ? (row.inv_rate * 100).toFixed(2) + "%" : ""
            let roic = row.roic != null ? (row.roic * 100).toFixed(2) + "%" : ""

            return [row.predict.toFixed(2), ev_capital, roic, incRate, invRate, risk]
        } else if (system === "6") {
            let ev_ebitda = row.ev_ebitda != null ? row.ev_ebitda.toFixed(2) : "-"
            let incRate = row.inc_rate != null ? (row.inc_rate * 100).toFixed(2) + "%" : "-"
            let risk = row.risk != null ? (row.risk).toFixed(2) + "%" : "-"
            let invRate = row.inv_rate != null ? (row.inv_rate * 100).toFixed(2) + "%" : ""
            let roic = row.roic != null ? (row.roic * 100).toFixed(2) + "%" : ""
            let tax = row.tax_rate != null ? (row.tax_rate * 100).toFixed(2) + "%" : ""
            return [row.predict.toFixed(2), ev_ebitda, invRate, incRate, risk, roic, tax]
        } else {
            return []
        }
    }

    return (
        <>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <RuleDialog1 dir={dir} refresh={fetchInitData} open={openSetting1} close={setOpenSetting1}></RuleDialog1> : null}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}
            {openSetting3 && <StockHistoryDialog sid={sid} open={openSetting3} close={setOpenSetting3} />}
            {openSetting4 && <StrageDialog open={openSetting4} close={setOpenSetting4} refresh={fetchInitData} />}
            {openSetting5 && <CompareCompany index={index} group={group} tp={system} open={openSetting5} close={setOpenSetting5} />}

            <Box sx={{ p: 0, width: "100%", overflow: 'hidden' }}>

                {/* <Box sx={{ mt: 0.5, width: "100%", p: 1 }}> */}
                <Stack direction={"row"} spacing={0.5} sx={{ p: 0.8, mt: 2 }}>
                    <FormControl fullWidth sx={{}} size="small" >
                        <InputLabel id="demo-simple-select-label">倍數</InputLabel>
                        <Select
                            id="demo-simple-select"
                            value={system}
                            label="倍數"
                            onChange={e => handleChange(e)}
                        >
                            <MenuItem value={"1"}>本益比</MenuItem>
                            <MenuItem value={"2"}>股價淨值比</MenuItem>
                            <MenuItem value={"3"}>{localStorage.getItem("noad") === "1" ? "股價營收比" : "股價營收比（VIP）"}</MenuItem>
                            <MenuItem value={"4"}>{localStorage.getItem("noad") === "1" ? "EV / 營收" : "EV / 營收（VIP）"}</MenuItem>
                            <MenuItem value={"5"}>{localStorage.getItem("noad") === "1" ? "EV / 資本" : "EV / 資本（VIP）"}</MenuItem>
                            <MenuItem value={"6"}>{localStorage.getItem("noad") === "1" ? "EV / EBITDA" : "EV / EBITDA（VIP）"}</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{}} size="small" >
                        <InputLabel id="demo-simple-direction-label">大分類</InputLabel>
                        <Select
                            id="demo-simple-direction"
                            value={group}
                            label="大分類"
                            onChange={e => handleGroupChange(e)}
                        >
                            <MenuItem value={-1}>產業別</MenuItem>
                            <MenuItem value={-2}>{localStorage.getItem("noad") === "1" ? "自訂類別" : "自訂類別（VIP）"}</MenuItem>
                            <MenuItem value={150}>{localStorage.getItem("noad") === "1" ? "系統分類Ⅰ" : "系統分類Ⅰ（VIP）"}</MenuItem>
                            <MenuItem value={200}>{localStorage.getItem("noad") === "1" ? "系統分類Ⅱ" : "系統分類Ⅱ（VIP）"}</MenuItem>
                            <MenuItem value={250}>{localStorage.getItem("noad") === "1" ? "系統分類Ⅲ" : "系統分類Ⅲ（VIP）"}</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl fullWidth size="small" >
                        <InputLabel id="group-index">小分類</InputLabel>
                        <Select
                            id="index"
                            value={index}
                            label="小分類"
                            onChange={e => handleIndexChange(e)}
                        >
                            {groupIndex != null && groupIndex.map(row => {
                                return <MenuItem value={row.index}>{getName(row)}</MenuItem>
                            }
                            )}
                        </Select>
                    </FormControl>
                </Stack>



                <TableContainer sx={{ position: 'absolute', top: 68 + Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, }} >
                    {localStorage.getItem('noad') !== "1" && <Adsense
                        client="ca-pub-8613274553211553"
                        slot="4386477039"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />}

                    <Table sx={{}} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell sx={{ zIndex: 999, position: "sticky", left: 0 }}>股票</StyledTableCell>
                                <StyledTableCell>類股</StyledTableCell>
                                {getColumn().map((rr, index) => {
                                    if (index === 0)
                                        return <StyledTableCell align="center">{rr}</StyledTableCell>
                                    else
                                        return <StyledTableCell align="right">{rr}</StyledTableCell>
                                })}
                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => (
                                <StyledTableRow key={row.company.stock_no}>
                                    <StyledTableCell
                                        sx={{ zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[50] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }}
                                        onClick={e => openDetail2(row.company.stock_no)} >
                                        {row.company.short_name} {row.company.stock_no}

                                        <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{}} >
                                        {row.company.inductry}
                                    </StyledTableCell>
                                    {getRowValue(row).map((rr, index) => {
                                        if (index === 0)
                                            return <StyledTableCell align="center">

                                                <Button size="small" variant={getLevelVariant(row)} disableElevation fullWidth color={getLevelColor(row)}>{rr} {getLevelWord(row)}</Button>
                                            </StyledTableCell>
                                        else return <StyledTableCell align="right">{rr}</StyledTableCell>
                                    })}


                                    <StyledTableCell align="center">
                                        <Button size="small" color="inherit" startIcon={<FavoriteIcon />} onClick={e => clickLove(row)} disableElevation variant='contained' sx={{
                                        }}>加入最愛</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination page={page} size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </>
    );
}
