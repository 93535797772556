import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import SellDialog from './SellDialog'
import AlertD from '../util/AlertD'
import BuyRuleDialog from './BuyRuleDialog'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import BuyDialog2 from './BuyDialog2'
import BuyDialog3 from './BuyDialog3'
import DetailKChart from '../rec/DetailKChart'
import ButtonGroup from '@mui/material/ButtonGroup';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoveSetting from './LoveSetting';
import ConfirmD from '../util/ConfirmD';
import TranDetail from './TranDetail';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 15,
        whiteSpace: "nowrap",
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function BuyListTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [showAlert, setShowAlert] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);

    const [row, setRow] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const [age, setAge] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const del = () => {
        setAlertMsg("確定刪除?")
        setShowConfirm(true)
    }

    const delOk = () => {
        setShowConfirm(false)

        let param = {
            sn: getId(),
            id: row.id
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }


        fetch("/relative/v1/custom/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    fetchData()
                }
            );

    }

    const fetchData = () => {
        setLoading(true)

        let param = {
            page: page,
            sn: getId(),
        }

        if (localStorage.getItem("relative_lic") != null) {
            param['lic'] = localStorage.getItem("relative_lic")
        }

        param = putInpQueryParam(param)

        fetch("/relative/v1/custom/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.love != null && result.love.length > 0) {
                            // setTotal(result.total_page)
                            // setTotal(0)
                            setResult(result.love)
                            // setBuyNum(result.count)
                        } else {
                            // setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, [page]);

    const openDetail = (row) => {
        setAnchorEl(null);
        setOpenSetting(true)
    }

    const openDetail3 = (id) => {
        setSid(id)
        setOpenSetting3(true)
    }



    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setRow(row)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };





    return (
        <>
            {openSetting ? <BuyDialog3 row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></BuyDialog3> : null}
            {openSetting1 ? <BuyRuleDialog row={row} refresh={fetchData} open={openSetting1} close={setOpenSetting1}></BuyRuleDialog> : null}
            {openSetting2 ? <BuyDialog2 row={row} refresh={fetchData} open={openSetting2} close={setOpenSetting2}></BuyDialog2> : null}
            {openSetting3 && <DetailKChart sid={sid} open={openSetting3} close={setOpenSetting3} />}
            {openSetting4 && <TranDetail row={row} sid={sid} open={openSetting4} close={setOpenSetting4} />}

            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {showConfirm ? <ConfirmD desc={alertMsg} open={showConfirm} close={setShowConfirm} ok={delOk}></ConfirmD> : null}


            <Box sx={{ p: 0, width: "100%", overflow: 'hidden' }}>
                <Typography sx={{ mt: 3, textAlign: "center" }} variant="h4">自訂類別
                    <IconButton onClick={e => setOpenSetting2(true)}>
                        <AddIcon />
                    </IconButton>
                    
                </Typography>
                <Typography fontSize={12} sx={{width:"100%",  textAlign: "center" }} >自訂類別可以到相對表中的大/小分類中找到。</Typography>
                

                <TableContainer  sx={{ position: 'absolute', top: 80 + Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, mt: 1.5 }}>
                    <Table sx={{  }} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell align="center" >名稱</StyledTableCell>
                                <StyledTableCell align="center" >數量</StyledTableCell>
                                <StyledTableCell align="center">比較公司</StyledTableCell>

                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => {
                                return <StyledTableRow key={row.id}>
                                    <StyledTableCell align="center">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.size}間
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        
                                        {row.desc.map(row => {
                                            return <Chip sx={{mr:0.3}} label={row.name + " " + row.sid + ""}></Chip>
                                        })}
                                    </StyledTableCell>


                                    <StyledTableCell align="center">
                                        <IconButton sx={{}} onClick={e => handleMenuClick(e, row)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            })}
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenuClose}
                            >

                                <MenuItem onClick={e => openDetail()}>修改</MenuItem>
                                <MenuItem onClick={e => del()}>刪除</MenuItem>
                            </Menu>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </>
    );
}
